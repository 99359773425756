.educations-header {
  font-size: 40px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}
.contact-heading-img-div > * {
  max-width: 90%;
  height: auto;
  padding: centre;
}

.blog-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1380px) {
  .educations-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .educations-header {
    font-size: 30px;
  }
}
