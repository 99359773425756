/* Loading */
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -35px 0 0 -56px;
  width: 112px;
  height: 70px;
}

.loading .finger {
  float: left;
  margin: 0 2px 0 0;
  width: 20px;
  height: 100%;
}

.loading .finger-1 {
  animation: finger-1-animation 2s infinite ease-out;
}

.loading .finger-1 span {
  animation: finger-1-animation-span 2s infinite ease-out;
}

.loading .finger-1 i {
  animation: finger-1-animation-i 2s infinite ease-out;
}

.loading .finger-2 {
  animation: finger-2-animation 2s infinite ease-out;
}

.loading .finger-2 span {
  animation: finger-2-animation-span 2s infinite ease-out;
}

.loading .finger-2 i {
  animation: finger-2-animation-i 2s infinite ease-out;
}

.loading .finger-3 {
  animation: finger-3-animation 2s infinite ease-out;
}

.loading .finger-3 span {
  animation: finger-3-animation-span 2s infinite ease-out;
}

.loading .finger-3 i {
  animation: finger-3-animation-i 2s infinite ease-out;
}

.loading .finger-4 {
  animation: finger-4-animation 2s infinite ease-out;
}

.loading .finger-4 span {
  animation: finger-4-animation-span 2s infinite ease-out;
}

.loading .finger-4 i {
  animation: finger-4-animation-i 2s infinite ease-out;
}

.loading .finger-item {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 8px 8px;
  background: #fff;
}

.loading .finger-item span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  padding: 5px 5px 0 5px;
}

.loading .finger-item span::before,
.loading .finger-item span::after {
  content: "";
  position: relative;
  display: block;
  margin: 0 0 2px 0;
  width: 100%;
  height: 2px;
  background: #4492f4;
}

.loading .finger-item i {
  position: absolute;
  left: 3px;
  bottom: 3px;
  width: 14px;
  height: 14px;
  border-radius: 10px 10px 7px 7px;
  background: #4492f4;
}

.loading .last-finger {
  position: relative;
  float: left;
  width: 24px;
  height: 100%;
  overflow: hidden;
}

.loading .last-finger-item {
  position: absolute;
  right: 0;
  top: 32px;
  width: 110%;
  height: 20px;
  border-radius: 0 5px 14px 0;
  background: #fff;
  animation: finger-5-animation 2s infinite linear;
}

.loading .last-finger-item i {
  position: absolute;
  left: 0;
  top: -8px;
  width: 22px;
  height: 8px;
  background: #fff;
  overflow: hidden;
}

.loading .last-finger-item i::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 34px;
  height: 20px;
  border-radius: 0 0 15px 15px;
  background: #4492f4;
}

/* Keyframes */
@keyframes finger-1-animation {
  0%, 20%, 100% {
    padding: 12px 0 5px 0;
  }
  29%, 35% {
    padding: 4px 0 24px 0;
  }
  41% {
    padding: 12px 0 5px 0;
  }
}

@keyframes finger-1-animation-span {
  0%, 20%, 100% {
    top: 0;
  }
  29%, 35% {
    top: -7px;
  }
  41% {
    top: 0;
  }
}

@keyframes finger-1-animation-i {
  0%, 20%, 100% {
    bottom: 3px;
    height: 14px;
    border-radius: 10px 10px 7px 7px;
  }
  29%, 35% {
    bottom: 8px;
    height: 12px;
    border-radius: 7px 7px 4px 4px;
  }
  41% {
    bottom: 3px;
    height: 14px;
    border-radius: 10px 10px 7px 7px;
  }
}

@keyframes finger-2-animation {
  0%, 24%, 100% {
    padding: 6px 0 2px 0;
  }
  33%, 39% {
    padding: 2px 0 16px 0;
  }
  45% {
    padding: 6px 0 2px 0;
  }
}

@keyframes finger-2-animation-span {
  0%, 24%, 100% {
    top: 0;
  }
  33%, 39% {
    top: -7px;
  }
  45% {
    top: 0;
  }
}

@keyframes finger-3-animation {
  0%, 28%, 100% {
    padding: 0 0 0 0;
  }
  37%, 43% {
    padding: 0 0 12px 0;
  }
  49% {
    padding: 0 0 0 0;
  }
}

@keyframes finger-3-animation-span {
  0%, 28%, 100% {
    top: 0;
  }
  37%, 43% {
    top: -7px;
  }
  49% {
    top: 0;
  }
}

@keyframes finger-3-animation-i {
  0%, 28%, 100% {
    bottom: 3px;
    height: 14px;
    border-radius: 10px 10px 7px 7px;
  }
  37%, 43% {
    bottom: 8px;
    height: 12px;
    border-radius: 7px 7px 4px 4px;
  }
  49% {
    bottom: 3px;
    height: 14px;
    border-radius: 10px 10px 7px 7px;
  }
}

@keyframes finger-4-animation {
   0%, 32%, 100% {
    padding: 8px 0 3px 0;
  }
  41%, 47% {
    padding: 4px 0 20px 0;
  }
  53% {
    padding: 8px 0 3px 0;
  }
}

@keyframes finger-4-animation-span {
  0%, 32%, 100% {
    top: 0;
  }
  41%, 47% {
    top: -7px;
  }
  53% {
    top: 0;
  }
}

@keyframes finger-4-animation-i {
  0%, 32%, 100% {
    bottom: 3px;
    height: 14px;
    border-radius: 10px 10px 7px 7px;
  }
  41%, 47% {
    bottom: 8px;
    height: 12px;
    border-radius: 7px 7px 4px 4px;
  }
  53% {
    bottom: 3px;
    height: 14px;
    border-radius: 10px 10px 7px 7px;
  }
}

@keyframes finger-5-animation {
  0%, 34%, 100% {
    top: 32px;
    right: 0;
    border-radius: 0 5px 14px 0;
    transform: rotate(0deg);
  }
  43%, 50% {
    top: 20px;
    right: 2px;
    border-radius: 0 8px 20px 0;
    transform: rotate(-12deg);
  }
  60% {
    top: 32px;
    right: 0;
    border-radius: 0 5px 14px 0;
    transform: rotate(0deg);
  }
}
